// extracted by mini-css-extract-plugin
export var activeGreen = "shift-module--active-green--7a003";
export var at1024__pr50p = "shift-module--at1024__pr50p--8573c";
export var at640__pr50p = "shift-module--at640__pr50p--5ec3a";
export var at768__w50p = "shift-module--at768__w50p--7d85a";
export var bg__nobleGray = "shift-module--bg__nobleGray--fbffd";
export var billetShadow = "shift-module--billet-shadow--19f2c";
export var black = "shift-module--black--31ba5";
export var btn = "shift-module--btn--5fc4a";
export var c = "shift-module--c--3fdab";
export var cc18sf = "shift-module--cc-18sf--c485e";
export var cc199t = "shift-module--cc-199t--a0c63";
export var cc1ada = "shift-module--cc-1ada--cb1b4";
export var cc1aev = "shift-module--cc-1aev--7d9c7";
export var cc1lut = "shift-module--cc-1lut--04b13";
export var cc1xry = "shift-module--cc-1xry--97444";
export var cc2hzo = "shift-module--cc-2hzo--464c8";
export var cc2n8m = "shift-module--cc-2n8m--82330";
export var cc4xbu = "shift-module--cc-4xbu--e08c6";
export var ccCaj5 = "shift-module--cc-caj5--da2a9";
export var ccEw5j = "shift-module--cc-ew5j--026b1";
export var ccG73w = "shift-module--cc-g73w--a2d18";
export var ccKgeu = "shift-module--cc-kgeu--b8ee7";
export var ccKv6t = "shift-module--cc-kv6t--3bc53";
export var ccPjpe = "shift-module--cc-pjpe--e4d7c";
export var ccUnoo = "shift-module--cc-unoo--5d50f";
export var centerAlign = "shift-module--center-align--32c42";
export var challenges = "shift-module--challenges--bdf59";
export var childAs__seeMore = "shift-module--childAs__seeMore--556f8";
export var colItem = "shift-module--col-item--adfa8";
export var colorScheme__background__grayPale = "shift-module--colorScheme__background__gray-pale--d7d6b";
export var colorScheme__buttonGold = "shift-module--colorScheme__buttonGold--fc436";
export var colorScheme__buttonGold__darkBackground = "shift-module--colorScheme__buttonGold__darkBackground--4b027";
export var colorScheme__linkGold = "shift-module--colorScheme__linkGold--8a519";
export var colorScheme__whiteButton = "shift-module--colorScheme__whiteButton--fef4b";
export var colorTextWrapper = "shift-module--color-text-wrapper--98fad";
export var coloredBackground__dark = "shift-module--coloredBackground__dark--8dda2";
export var coloredBackground__light = "shift-module--coloredBackground__light--c5a28";
export var common__article__section = "shift-module--common__article__section--327b3";
export var common__article__section__doubleIndentBottom = "shift-module--common__article__section__doubleIndentBottom--d4d27";
export var common__article__section__zeroIndentBottom = "shift-module--common__article__section__zeroIndentBottom--37c50";
export var common__article__section__zeroIndentTop = "shift-module--common__article__section__zeroIndentTop--ede74";
export var common__chips = "shift-module--common__chips--746dd";
export var common__headline = "shift-module--common__headline--d9de7";
export var common__headline__beveledBottom = "shift-module--common__headline__beveledBottom--f527d";
export var common__headline__bgLoop = "shift-module--common__headline__bgLoop--80a1e";
export var common__leadQuote = "shift-module--common__leadQuote--9755b";
export var common__projectsTeaser = "shift-module--common__projects-teaser--9af09";
export var common__scrollableTarget = "shift-module--common__scrollableTarget--e653b";
export var common__teaser = "shift-module--common__teaser--e02d5";
export var common__teaser__button = "shift-module--common__teaser__button--41422";
export var common__textOutline = "shift-module--common__textOutline--98f29";
export var container = "shift-module--container--f72c4";
export var crispChatbox = "shift-module--crisp-chatbox--bf826";
export var crispClient = "shift-module--crisp-client--6ff36";
export var darkBackground = "shift-module--darkBackground--a6b19";
export var darkTxt = "shift-module--darkTxt--b437c";
export var fatButton = "shift-module--fatButton--bb0a9";
export var feedback = "shift-module--feedback--e0ca2";
export var fidelity = "shift-module--fidelity--d6701";
export var first = "shift-module--first--8a836";
export var fs1o5 = "shift-module--fs1o5--ab80e";
export var fullWidth = "shift-module--fullWidth--08043";
export var goldBackground = "shift-module--goldBackground--feb42";
export var goldButton = "shift-module--goldButton--3f8b1";
export var goldButton__casePorter = "shift-module--goldButton__casePorter--9aa3d";
export var goldButton__centered = "shift-module--goldButton__centered--62732";
export var goldButton__w14 = "shift-module--goldButton__w14--e3ae2";
export var goldFatLink = "shift-module--goldFatLink--779ef";
export var gray = "shift-module--gray--cd074";
export var green = "shift-module--green--c76f3";
export var icomoon = "shift-module--icomoon--149ba";
export var identifier = "shift-module--identifier--e20b7";
export var img = "shift-module--img--43e41";
export var imgLeft = "shift-module--img-left--43a71";
export var l = "shift-module--l--ee33f";
export var large = "shift-module--large--1bb48";
export var lg = "shift-module--lg--71417";
export var lineBreak = "shift-module--lineBreak--282db";
export var linksWrapper = "shift-module--links-wrapper--e399a";
export var logo = "shift-module--logo--ba01c";
export var lowercase = "shift-module--lowercase--81696";
export var maxw20 = "shift-module--maxw20--26b59";
export var mb2 = "shift-module--mb2--60c57";
export var md = "shift-module--md--dfc91";
export var mockup = "shift-module--mockup--bcf70";
export var mt2 = "shift-module--mt2--2cd20";
export var nobr = "shift-module--nobr--f0b32";
export var order = "shift-module--order--a34b3";
export var personaInfo = "shift-module--persona-info--6f397";
export var primaryButton = "shift-module--primaryButton--f0881";
export var process = "shift-module--process--ebf67";
export var processStepsContent = "shift-module--process-steps-content--cd853";
export var processStepsGrid = "shift-module--process-steps-grid--99a4c";
export var processStepsWrapper = "shift-module--process-steps-wrapper--c811c";
export var products = "shift-module--products--a0f31";
export var pt2 = "shift-module--pt2--d277e";
export var purple = "shift-module--purple--62013";
export var quotesIcon = "shift-module--quotes-icon--d467d";
export var r = "shift-module--r--41ca3";
export var research = "shift-module--research--727d0";
export var reverse = "shift-module--reverse--02437";
export var scheme = "shift-module--scheme--932c8";
export var second = "shift-module--second--0bb72";
export var sectionTitle = "shift-module--section-title--36dfa";
export var seeMore = "shift-module--seeMore--fa7f3";
export var shiftPage = "shift-module--shift-page--1ce65";
export var sitemap = "shift-module--sitemap--5610c";
export var small = "shift-module--small--921c8";
export var squarePicWrapper = "shift-module--square-pic-wrapper--f523b";
export var swapOrder = "shift-module--swap-order--74887";
export var technologies = "shift-module--technologies--51f61";
export var technologiesSection = "shift-module--technologies-section--1aa28";
export var textBlack = "shift-module--text-black--d48d4";
export var textBlock = "shift-module--text-block--b9d77";
export var textImage = "shift-module--text-image--503a4";
export var textLeft = "shift-module--text-left--11bd9";
export var textLeft__ul = "shift-module--textLeft__ul--c8a94";
export var textOutline = "shift-module--textOutline--33fbb";
export var threeColsWrapper = "shift-module--three-cols-wrapper--61b81";
export var topBlock = "shift-module--top-block--2d07d";
export var transition = "shift-module--transition--8586e";
export var transitionBackground = "shift-module--transition-background--47d04";
export var transitionReverse = "shift-module--transition-reverse--30850";
export var twoColsWrapper = "shift-module--two-cols-wrapper--2482e";
export var twoImgWrapper = "shift-module--two-img-wrapper--3a347";
export var upTo2cols = "shift-module--upTo2cols--ac14e";
export var upTo2colsImgTxt = "shift-module--upTo2colsImgTxt--3ff3c";
export var uppercase = "shift-module--uppercase--73274";
export var v2022 = "shift-module--v2022--aa3a8";
export var whiteButton = "shift-module--whiteButton--ededa";