const PERSONAS = [
  {
    name: "Wade Warren - 27",
    identifier: "Junior",
    image: "junior",
  },
  {
    name: "Lara Holms - 47",
    identifier: "Teacher",
    image: "trainer",
  },
  {
    name: "Bennett - 35",
    identifier: "Profi",
    image: "profi",
  },
]

const COLUMNS = [1, 2, 3, 4, 5, 6, 7, 8]

const PROGRESS_CONTENT = [
  {
    text: "Stakeholders Interview",
    bg_color: "purple",
    width: "",
  },
  {
    text: "Scope Definition",
    bg_color: "purple",
    width: "",
  },
  {
    text: "Business research",
    bg_color: "purple",
    width: "md",
  },
  {
    text: "User interviews",
    bg_color: "gray",
    width: "",
  },
  {
    text: "User personas",
    bg_color: "gray",
    width: "md",
  },
  {
    text: "Information Architecture",
    bg_color: "gray",
    width: "md",
  },
  {
    text: "Wireframing",
    bg_color: "green",
    width: "md",
  },
  {
    text: "User interface design",
    bg_color: "green",
    width: "md",
  },
  {
    text: "Design system",
    bg_color: "green",
    width: "lg",
  },
  {
    text: "Usability Testing",
    bg_color: "black",
    width: "md",
  },
  {
    text: "Design Update",
    bg_color: "black",
    width: "",
  },
]

const TECHNOLOGIES = [
  {
    name: "UX/UI design",
    icon: "programming.svg",
  },
  {
    name: "React Native",
    icon: "logos/react-native.svg",
  },
  {
    name: "Laravel",
    icon: "logos/laravel.svg",
  },
  {
    name: "Node JS",
    icon: "logos/nodejs-full.svg",
  },
]

export {
  PERSONAS,
  COLUMNS,
  PROGRESS_CONTENT,
  TECHNOLOGIES
}
